import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"brig pa-3"},[_c('SocketWatch',{attrs:{"tables":['brigs']},on:{"loadData":_vm.loadData}}),_c(VCard,[_c(VSimpleTable,{attrs:{"dense":!_vm.isMobile},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Бригада")]),_c('th',{staticClass:"text-left"},[_vm._v("Район")]),_c('th',{staticClass:"text-left"},[_vm._v("Монтажники")]),_c('th',{staticClass:"text-right"},[_vm._v("Действия")])])]),_c('tbody',_vm._l((_vm.brigs),function(brig,index_id){return _c('tr',{key:index_id},[_c('td',[_vm._v(_vm._s(brig.displayName))]),_c('td',[_vm._v(_vm._s(brig.location))]),_c('td',[(brig.users_data && brig.users_data.length > 0)?_c('span',_vm._l((brig.users_data),function(user,index_user){return _c('span',{key:index_user},[_vm._v(" "+_vm._s(user.displayName)),(index_user + 1 < brig.users_data.length)?_c('span',[_vm._v(", ")]):_vm._e()])}),0):_c('span',[_vm._v("Не назначены")])]),_c('td',{staticClass:"text-right"},[_c(VBtn,{attrs:{"icon":"","small":"","color":"#424242"},on:{"click":function($event){return _vm.editBrig(brig)}}},[_c(VIcon,{attrs:{"size":"18"}},[_vm._v("mdi-pencil")])],1),_c(VBtn,{attrs:{"icon":"","small":"","color":"#bf4037"},on:{"click":function($event){return _vm.deleteBrig(brig)}}},[_c(VIcon,{attrs:{"size":"18"}},[_vm._v("mdi-trash-can-outline")])],1)],1)])}),0)]},proxy:true}])}),_c(VCardTitle,[_c(VSpacer),_c(VBtn,{attrs:{"small":""},on:{"click":function($event){return _vm.createBrig()}}},[_c(VIcon,[_vm._v("mdi-account-multiple-plus")]),_c('span',{staticClass:"pl-2"},[_vm._v("Добавить бригаду")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }