<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      :fullscreen="isMobile"
      :max-width="!isMobile ? '600px' : ''"
      transition="dialog-bottom-transition"
      :scrollable="!isMobile"
      :persistent="!isMobile"
    >
      <v-card :tile="isMobile">
        <v-toolbar v-if="isMobile" color="rgb(224, 166, 119)" tile style="position: sticky; top: 0px; z-index: 20;">
          <v-toolbar-title v-if="edit" class="white--text">Редактировние бригады</v-toolbar-title>
          <v-toolbar-title v-else class="white--text">Создание бригады</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title v-else>
          <span v-if="edit" class="headline">Редактировние бригады</span>
          <span v-else class="headline">Создание бригады</span>
          <v-spacer />
          <v-btn icon color="rgb(224, 166, 119)" @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container :class="{ 'pa-0': isMobile }">
            <v-form ref="formCreateUser">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    color="rgb(224, 166, 119)"
                    v-model="form.displayName"
                    label="Название бригады*"
                    :rules="$fieldRules.field"
                    required
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    color="rgb(224, 166, 119)"
                    v-model="form.location"
                    :items="locations"
                    item-text="name"
                    label="Район*"
                    :rules="$fieldRules.field"
                    required
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="form.users"
                    :items="listWorkers"
                    item-text="displayName"
                    :label="
                      `${listWorkers.length > 0 
                        ? 'Добавьте монтажников'
                        : 'Все монтажники назначены'
                      }`
                    "
                    multiple
                    small-chips
                    deletable-chips
                    return-object
                    color="rgb(224, 166, 119)"
                    item-color="rgb(224, 166, 119)"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="orange" text @click="save()">
            <span v-if="edit">Сохранить</span>
            <span v-else>Создать</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import { RoleText } from '@/helpers'

export default {
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data: () => ({
    isMobile: isMobile,
    roleText: RoleText,
    dialog: true,
    showPass: false,
    errors: {
      email: []
    },
    form: {
      displayName: '',
      location: null,
      users: []
    },
    users: []
  }),
  computed: {
    userRoles() {
      const arrUserRoles = []
      for (let key in this.roleText) {
        arrUserRoles.push({ value: key, text: this.roleText[key] })
      }
      return arrUserRoles
    },
    listWorkers() {
      let freeWorkers = this.$store.getters['users/getWorkers']
      let workers = [ ...this.users, ...freeWorkers.filter(x => !x.brig) ]
      return workers
    },
    locations() {
      return this.$store.getters['locations/getLocations']
    }
  },
  created() {
    if (this.data && this.data.id) {
      this.form.displayName = this.data.displayName
      this.form.location = this.data.location
      this.form.users = this.data.users_data
      this.users = this.data.users_data
      // console.log(this.data)
    }
  },
  methods: {
    getChanges() {
      let obj = {}
      for (let key in this.form) {
        if (this.form[key] && this.data[key] && (this.form[key] != this.data[key])) {
          obj[key] = this.form[key]
        }
      }
      return obj
    },
    async save() {
      const errors = []
      const validate = await this.$refs.formCreateUser.validate()
      errors.push(validate)

      if (!errors.includes(false)) {
        if (!this.edit) {
          this.$emit('close', this.form)
        } else {
          this.$emit('close', await this.getChanges())
        }
      }
    },
    close() {
      this.dialog = false
      setTimeout(() => {
        this.$emit('close')
      }, 200)
    }
  }
}
</script>
